<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pa-0 text-xs-left">
                <a href="javascript: history.go(-1)" class="pa-0">
                    <v-btn flat fab small class="pa-0">
                        <i class="fas fa-arrow-left headline"></i>
                    </v-btn>
                </a>                
                Njangi: Groups
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tabs
                    v-model="tabs"
                    color="transparent"
                    slider-color="white"
                    slot="extension"
            >
                <v-tab 
                        v-for="tabsItem in tabsItems"
                        :key="tabsItem.id"
                        :to="tabsItem.link"
                >
                    <span class="pl-1 pr-1" >{{ tabsItem.title }}</span>
                </v-tab>
            </v-tabs>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <router-view/>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabs: null,
                tabsItems: [
                    {id: 1, title: 'Active', link: '/njangi/groups/types/Active'},
                    {id: 2, title: 'Applications', link: '/njangi/groups/types/Applied'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>
